<template>
  <div>
    <!--面包屑-->
    <div class="page-breadcrumb">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>门店</el-breadcrumb-item>
      </el-breadcrumb>
    </div>

    <!--内容-->
    <div class="page-content-x">
      <!--搜索-->
      <div v-show="issearch" class="page-content-search">
        <el-form ref="form" :model="search" size="medium" :label-width="this.env.search_label_width">
          <el-row>
            <el-col :span="6">
              <el-form-item label="门店名称">
                <el-input v-model="search.store_name"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="负责人">
                <el-input v-model="search.responsible"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="门店电话">
                <el-input v-model="search.store_phone"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="城市">
                <el-input v-model="search.city"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="上架状态">
                <el-select v-model="search.publish_state" placeholder="请选择类型" style="width: 100%">
                  <el-option label="全部" value=""></el-option>
                  <el-option label="上架" :value="1"></el-option>
                  <el-option label="下架" :value="0"></el-option>
                  <el-option label="审核中" :value="2"></el-option>
                  <el-option label="不通过" :value="3"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="运动品类">
                <el-input v-model="search.motion_category_two"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="创建时间">
                <el-date-picker style="width: 100%" v-model="searchtime" type="daterange" range-separator="至"
                  start-placeholder="开始日期" end-placeholder="结束日期" format="yyyy 年 MM 月 dd 日" value-format="yyyy-MM-dd">
                </el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row class="page-content-search-button">
            <el-col :span="24">
              <el-form-item>
                <el-button icon="el-icon-search" size="medium" type="primary" @click="is_search">搜索
                </el-button>
                <el-button icon="el-icon-circle-close" size="medium" @click="init">清空搜索
                </el-button>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <!--标题-->
      <el-row>
        <el-col :span="12">
          <h3>{{ page_name }}</h3>
        </el-col>
        <el-col :span="12">
          <div class="top-operation-button">
            <el-button v-if="issearch" icon="el-icon-caret-top" size="medium" type="primary"
              @click="issearch = !issearch">隐藏搜索
            </el-button>
            <el-button v-else icon="el-icon-caret-bottom" size="medium" type="primary" @click="issearch = !issearch">显示搜索
            </el-button>
            <el-button v-if="is_auth('store.store.issave')" icon="el-icon-plus" size="medium" type="primary"
              @click="tosee()">添加
            </el-button>
          </div>
        </el-col>
      </el-row>
      <div style="height: 20px"></div>
      <!--列表-->
      <el-table size="medium" border v-loading="loading" :data="tableData" style="width: 100%">
        <el-table-column prop="company_name" label="所属企业" width="120" show-overflow-tooltip> </el-table-column>
        <el-table-column prop="store_name" label="门店名称" width="120" show-overflow-tooltip>
          <template slot-scope="scope">
            <el-tag size="mini" v-if="scope.row.oss_arrangement === 1">平台可排课 </el-tag>&nbsp;&nbsp;
            <span>{{ scope.row.store_name }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="qrcode" label="门店二维码" width="130">
          <template slot-scope="scope">
            <viewer v-if="!is_empty(scope.row.qrcode)">
              <el-avatar shape="square" :size="22" :src="scope.row.qrcode"></el-avatar>
            </viewer>
            <div v-else>
              <el-button v-if="is_auth('store.store.qrcode')" @click="get_qrcode(scope.row.store_uuid)" type="primary"
                size="mini">门店二维码
              </el-button>
              <span v-else>-</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="examine_status" label="审核状态" width="100">
          <template slot-scope="scope">
            <el-tag size="mini" v-if="scope.row.examine_status === 0">审核中</el-tag>
            <el-tag size="mini" v-if="scope.row.examine_status === 1" type="success">审核通过</el-tag>
            <el-tag size="mini" v-if="scope.row.examine_status === 2" type="danger">审核失败</el-tag>
            <el-tag size="mini" v-if="scope.row.examine_status === 3" type="danger">违规下架</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="publish_state" label="发布状态">
          <template slot-scope="scope">
            <el-tag size="mini" v-if="scope.row.publish_state === 0" type="warning">未发布
            </el-tag>
            <el-tag size="mini" v-if="scope.row.publish_state === 1" type="success">已发布
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="publish_state" label="运动品类" min-width="100" show-overflow-tooltip>
          <template slot-scope="scope">
            <el-tag v-for="item in scope.row.motion_category_two" :key="item.id" class="form-tag_colour-tag" effect="dark"
              size="mini">
              {{ item.name }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="responsible" label="负责人" width="120" show-overflow-tooltip> </el-table-column>
        <el-table-column prop="store_phone" label="门店电话" width="120" show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="city" label="城市"> </el-table-column>
        <el-table-column prop="address" label="地址" min-width="120" show-overflow-tooltip> </el-table-column>
        <el-table-column prop="create_time" label="创建时间" width="120" show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="update_time" label="更新时间" width="120" show-overflow-tooltip>
        </el-table-column>
        <el-table-column fixed="right" label="操作" width="320">
          <template slot-scope="scope">
            <el-button v-if="is_auth('store.store.getinfo')" @click="tosee(scope.row.store_uuid, 'watch')" size="mini">查看
            </el-button>
            <el-button v-if="is_auth('store.store.getinfo')" @click="tosee(scope.row.store_uuid)" size="mini">编辑
            </el-button>
            <el-button
              v-if="is_auth('store.store.upapply') && scope.row.publish_state === 0 && scope.row.examine_status === 1"
              @click="
                operation_tip(scope.row.store_uuid, scope.row.store_name, 'upapply')
                " type="success" size="mini">上架发布
            </el-button>
            <el-button v-if="is_auth('store.store.withdraw') && scope.row.publish_state === 1"
              @click="operation_tip(scope.row.store_uuid, scope.row.store_name, 'withdraw')" type="warning"
              size="mini">取消发布
            </el-button>

            <el-popover placement="left" width="80" trigger="click" style="margin-left: 12px;">
              <div>
                <el-button style="width:100%;display: block;margin-bottom: 12px;" type="primary" size="mini" v-if="scope.row.consumer_card_extend > 0 &&
                  is_auth('store.store.issave') &&
                  is_auth('store.store.consumercardset')
                  " @click="set_consumercard(scope.row)">设置消费卡
                </el-button>
                <el-button style="width:100%;display: block;margin-bottom: 12px;margin-left: 0;"
                  v-if="is_auth('store.store.settag')" @click="set_tag(scope.row)" type="primary" size="mini">设置品类
                </el-button>
                <el-button style="width:100%;display: block;margin-bottom: 12px;margin-left: 0;"
                  v-if="is_auth('store.store.isdel')"
                  @click="operation_tip(scope.row.store_uuid, scope.row.store_name, 'isdel')" type="danger" size="mini">删除
                </el-button>
                <el-button style="width:100%;display: block;margin-bottom: 12px;margin-left: 0;"
                  @click="operation_tip(scope.row.examine_reason, scope.row.title, 'watch')" type="danger"
                  :disabled="scope.row.examine_status !== 3 && scope.row.examine_status !== 2" size="mini">查看原因
                </el-button>
              </div>
              <el-button size="mini" type="primary" slot="reference">更多
              </el-button>
            </el-popover>
          </template>
        </el-table-column>
      </el-table>
      <div style="height: 20px"></div>
      <!--分页-->
      <el-pagination @current-change="getlist" :page-size="this.env.pageSize" :pager-count="7" background
        layout="prev, pager, next, total" :current-page.sync="page" :total="count">
      </el-pagination>

      <!--设置运动品类-->
      <el-dialog v-if="is_auth('store.store.settag')" title="设置品类" :visible.sync="settagPage" width="50%"
        :close-on-press-escape="false" :close-on-click-modal="false">
        <el-row>
          <el-form size="medium" ref="form" label-width="120px">
            <el-col :span="24">
              <el-form-item label="门店名称">
                <span class="form-span-text">{{ tagdata.store_name }}</span>
              </el-form-item>
              <el-form-item label="运动品类">
                <div class="category-select">
                  <el-select @change="add_tag" v-model="select_tag" filterable placeholder="请选择品类" style="width: 100%">
                    <el-option v-for=" item  in  tag_list " :key="item.tag_uuid" :label="item.tag_name"
                      :value="item.tag_name + '|' + item.tag_colour">
                    </el-option>
                  </el-select>
                  <div class="category-select-child" @click="isMotionCategory = true"></div>
                </div>
                <div class="form-tag_colour-div">
                  <el-tag :key="item.id" v-for="( item, index ) in  tagdata.motion_category_two "
                    class="form-tag_colour-tag" effect="dark" closable @close="tagClose(index)">
                    <!-- ` item.pidName + '-' + item.name` -->
                    {{ `${item.pidName ? item.pidName + '-' + item.name : item.name} ` }}
                  </el-tag>
                </div>
              </el-form-item>
            </el-col>
          </el-form>
        </el-row>
        <el-row style="margin: 10px 0px">
          <!-- v-if="is_auth('store.store.settag')" -->
          <el-button size="medium" type="primary" @click="saveCategoryData">确 定 </el-button>
          <el-button size="medium" @click="settagPage = false">取 消</el-button>
        </el-row>
      </el-dialog>

      <!--设置消费卡-->
      <el-dialog v-if="is_auth('store.store.issave')" title="设置消费卡" :visible.sync="setconsumercardPage" width="50%"
        :close-on-press-escape="false" :close-on-click-modal="false">
        <el-row>
          <el-form size="medium" ref="form" label-width="120px">
            <el-col :span="24">
              <el-form-item label="门店名称">
                <span class="form-span-text">{{ storeinfo.store_name }}</span>
              </el-form-item>
              <el-form-item label="开启消费卡">
                <el-switch v-if="is_auth('store.store.consumercardset')" v-model="storeinfo.use_consumer_card"
                  :active-value="1" :inactive-value="0"
                  @change="use_consumer_card_set($event, storeinfo.store_uuid)"></el-switch>
              </el-form-item>
              <el-form-item label="消费卡产品">
                <el-checkbox-group v-model="storeinfo.product_line_all">
                  <el-checkbox :label="1" name="type">团课</el-checkbox>
                  <el-checkbox :label="2" name="type">训练营</el-checkbox>
                  <el-checkbox :label="3" name="type">私教</el-checkbox>
                  <el-checkbox :label="4" name="type">商品</el-checkbox>
                  <el-checkbox :label="5" name="type">活动</el-checkbox>
                  <el-checkbox :label="6" name="type">赛事</el-checkbox>
                </el-checkbox-group>
              </el-form-item>
            </el-col>
          </el-form>
        </el-row>
        <el-row style="margin: 10px 0px">
          <el-button size="medium" type="primary" @click="issave_product_line_all">保 存
          </el-button>
          <el-button size="medium" @click="setconsumercardPage = false">取 消</el-button>
        </el-row>
      </el-dialog>

      <MotionCategory v-model="isMotionCategory" :motion_category_two="tagdata.motion_category_two"
        @selectData="onSelectCategoryData" />
    </div>
  </div>
</template>
<script>
import MotionCategory from '@/components/MotionCategory'
export default {
  components: {
    MotionCategory
  },
  data() {
    return {
      page_name: "门店",
      loading: true, // 加载状态
      issearch: true, // 搜索是否展示
      search: {}, // 搜索内容
      searchtime: null, // 时间条件
      count: 0, // 数据总条数
      page: 1, // 当前页数
      tableData: [], // 列表内容
      settagPage: false,
      setconsumercardPage: false,
      select_tag: "", // 选中的标签
      tag_list: [], // 标签列表
      // 标签数据
      tagdata: {
        store_name: "",
        motion_category_two: [],
      },
      storeinfo: {}, // 门店数据
      isMotionCategory: false, // 是否展示运动品类弹窗
      select_Motion: '', // 筛选运动品类
    };
  },
  // 创建
  created() {
    this.init();
    this.gettag();
  },
  // 安装
  mounted() { },
  methods: {
    // 操作
    get_qrcode(store_uuid = "") {
      let postdata = {
        api_name: "store.store.qrcode",
        token: this.Tool.get_l_cache("token"),
        store_uuid: store_uuid,
      };
      this.loading = true;
      this.Tool.post_data("merchant", postdata, (json) => {
        this.loading = false;
        if (json.code === 0) {
          this.$message({
            message: "小程序码获取成功",
            type: "success",
            duration: this.env.message_duration,
            onClose: () => {
              this.getlist(); // 刷新列表(重新搜索)
            },
          });
        } else {
          this.Tool.errormes(json);
        }
      });
    },
    handleClick() {
      this.isMotionCategory = true
    },
    // 判断是否为空
    is_empty(data) {
      return this.Tool.is_empty(data);
    },
    // 权限判断
    is_auth(auth) {
      return this.Tool.is_auth(auth);
    },
    // 初始化
    init() {
      this.search = {
        store_name: "",
        responsible: "",
        store_phone: "",
        city: "",
        publish_state: "",
        motion_category_two: "",
      };
      this.searchtime = null;
      this.is_search();
    },
    //搜索
    is_search() {
      this.page = 1;
      this.getlist();
    },
    // 获取列表
    getlist() {
      let postdata = {
        api_name: "store.store.getlist",
        token: this.Tool.get_l_cache("token"),
        pagesize: this.env.pageSize,
        page: this.page,

      };
      // 判断时间条件
      if (this.searchtime !== null) {
        postdata.starttime = this.searchtime[0];
        postdata.endtime = this.searchtime[1];
      }
      // 非必须参数（搜索条件）
      Object.assign(postdata, this.search);
      this.loading = true;
      this.Tool.post_data("merchant", postdata, (json) => {
        this.loading = false;
        if (json.code === 0) {
          this.tableData = json.data.list;

          this.count = json.data.count;
        } else {
          this.tableData = [];
          this.count = 0;
          this.Tool.errormes(json);
        }
      });
    },
    // 获取标签
    gettag() {
      let postdata = {
        api_name: "store.store.gettag",
        token: this.Tool.get_l_cache("token"),
      };
      this.loading = true;
      this.Tool.post_data("merchant", postdata, (json) => {
        this.loading = false;
        if (json.code === 0) {
          this.tag_list = json.data;
        } else {
          this.Tool.errormes(json);
        }
      });
    },
    // 操作提示
    operation_tip(store_uuid = '', title = '', operation = '', state) {

      let tip = ''
      let options = {}
      // 发布
      if (operation === 'upapply') {
        tip = '上架【' + title + '】？'
      }
      // 下架
      if (operation === 'withdraw') {
        tip = '<p style="text-align:center">取消发布【' + title + '】？</p><p style="color:red;text-align:center">（取消发布后，该门店信息在用户端小程序不可见哦～）</p>'
        options["dangerouslyUseHTMLString"] = true;
      }
      // 删除
      if (operation === 'isdel') {
        tip = '删除【' + title + '】？'
      }

      // 查看原因
      if (operation === 'watch') {
        tip = store_uuid
      }
      // 停止报名
      if (operation === 'buystate') {
        tip = `${state === 1 ? '停止报名' : '恢复报名'}【${title}】`;
      }

      // 弹出二次确认
      this.$confirm(tip, '确认信息', options)
        .then(() => {
          if (operation !== 'watch') {
            this.isoperation(store_uuid, operation, state)
          }
        })
        .catch(() => {
        });
    },
    // 操作
    isoperation(store_uuid = '', operation = '', state) {
      let postdata = {
        api_name: "store.store." + operation,
        token: this.Tool.get_l_cache('token'),
        store_uuid,
      }
      if (operation === 'buystate') {
        postdata["buy_state"] = state;
      }
      this.loading = true
      this.Tool.post_data('merchant', postdata, (json) => {
        this.loading = false
        if (json.code === 0) {
          this.$message({
            message: '操作成功', type: 'success',
            duration: this.env.message_duration,
            onClose: () => {
              this.getlist()    // 刷新列表(重新搜索)
            }
          });
        } else {
          this.Tool.errormes(json)
        }
      })
    },
    // 查看
    tosee(store_uuid = "", type) {
      if (store_uuid === "") {
        this.$router.push({ path: "/store/store/edit" });
      } else {
        if (type === 'watch') {
          this.$router.push({
            path: "/store/store/info",
            query: { store_uuid: store_uuid },
          });
        } else {
          this.$router.push({
            path: "/store/store/edit",
            query: { store_uuid: store_uuid },
          });

        }
      }
    },
    // 设置标签
    set_tag(data) {
      console.log("data", data);
      this.tagdata = JSON.parse(JSON.stringify(data));
      this.settagPage = true;
    },
    // 添加标签
    add_tag() {
      let select_tag = this.select_tag.split("|");
      let tag = {
        tag_name: select_tag[0],
        tag_colour: select_tag[1],
      };
      if (!this.tag_exist(tag.tag_name)) {
        this.tagdata.motion_category_two.push(tag);
      }
      this.select_tag = "";
    },
    // 判断标签是否存在
    tag_exist(tag_name) {
      if (this.tagdata.motion_category_two.length > 0) {
        for (let v of this.tagdata.motion_category_two) {
          if (tag_name == v.tag_name) {
            return true;
          }
        }
        return false;
      } else {
        return false;
      }
    },
    // 删除标签
    tagClose(index) {
      this.tagdata.motion_category_two.splice(index, 1);
      this.select_tag = this.tagdata.motion_category_two.length > 1 ? this.tagdata.motion_category_two[0].name + ' ...' : this.tagdata.motion_category_two.length === 1 ? this.tagdata.motion_category_two[0].name : ''

    },
    // 保存标签
    issavetag() {
      let postdata = {
        api_name: "store.store.settag",
        token: this.Tool.get_l_cache("token"),
      };
      Object.assign(postdata, this.tagdata);

      this.loading = true;
      this.Tool.post_data("merchant", postdata, (json) => {
        this.loading = false;
        this.getlist(); // 刷新列表(重新搜索)
        if (json.code === 0) {
          this.$message({
            message: "操作成功",
            type: "success",
            duration: this.env.message_duration,
            onClose: () => {
              this.settagPage = false; // 关闭窗口
            },
          });
        } else {
          this.Tool.errormes(json);
        }
      });
    },
    // 是否使用消费卡
    use_consumer_card_set(val, store_uuid) {
      let postdata = {
        api_name: "store.store.consumercardset",
        token: this.Tool.get_l_cache("token"),
        store_uuid: store_uuid,
        use_consumer_card: val,
      };
      this.loading = true;
      this.Tool.post_data("merchant", postdata, (json) => {
        this.loading = false;
        if (json.code === 0) {
          this.$message({
            message: "操作成功",
            type: "success",
            duration: this.env.message_duration,
            onClose: () => { },
          });
        } else {
          this.$message.error(json.message);
        }
      });
    },
    // 设置消费卡产品线
    set_consumercard(data) {
      console.log("data", data);
      this.storeinfo = data;
      this.setconsumercardPage = true;
    },

    // 保存消费卡产品
    issave_product_line_all() {
      let postdata = {
        api_name: "store.store.issave",
        token: this.Tool.get_l_cache("token"),
        set_product_line_all: 1,
        store_uuid: this.storeinfo.store_uuid,
        product_line_all: this.storeinfo.product_line_all,
      };

      this.loading = true;
      this.setconsumercardPage = false;
      this.Tool.post_data("merchant", postdata, (json) => {
        this.loading = false;
        if (json.code === 0) {
          this.$message({
            message: "操作成功",
            type: "success",
            duration: this.env.message_duration,
            onClose: () => {
              this.storeinfo = {};
            },
          });
        } else {
          this.Tool.errormes(json);
        }
      });
    },
    // 完成运动品类选择
    onSelectCategoryData(data) {
      this.tagdata.motion_category_two = data;
      if (this.settagPage) {

        this.select_tag = this.tagdata.motion_category_two.length > 1 ? this.tagdata.motion_category_two[0].name + ' ...' : this.tagdata.motion_category_two.length === 1 ? this.tagdata.motion_category_two[0].name : ''
      } else {
        this.select_Motion = this.tagdata.motion_category_two.length > 1 ? this.tagdata.motion_category_two[0].name + ' ...' : this.tagdata.motion_category_two.length === 1 ? this.tagdata.motion_category_two[0].name : ''
        let motion_category_two = []
        data.forEach(element => {
          motion_category_two.push(element.name)
        });
        this.search.motion_category_two = motion_category_two.join(',')
      }

    },
    // 保存运动品类
    saveCategoryData() {
      let postdata = {
        api_name: "store.store.setcategory",
        token: this.Tool.get_l_cache("token"),
        store_uuid: this.tagdata.store_uuid,
        motion_category_two: this.tagdata.motion_category_two.map(item => item.id)
      };

      this.loading = true;
      this.Tool.post_data("merchant", postdata, (json) => {
        this.loading = false;
        this.getlist(); // 刷新列表(重新搜索)
        if (json.code === 0) {
          this.$message({
            message: "操作成功",
            type: "success",
            duration: this.env.message_duration,
            onClose: () => {
              this.settagPage = false; // 关闭窗口
            },
          });
        } else {
          this.Tool.errormes(json);
        }
      });
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.form-tag_colour-div {
  min-height: 200px;
}

.category-select {
  position: relative;
}

.category-select-child {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.el-popover {
  min-width: 80px;
}
</style>
